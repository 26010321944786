import { 
  GET_ALL_ASSET_LIST, 
  GET_TEMPLATE_WIDGETS, 
  GET_WIDGET_DETAILS_TEMPLATE, 
  GET_GROUP_WIDGET_DETAILS_TEMPLATE,
  DELETE_WIDGET_OF_TEMPLATE,
  EDIT_DASHBOARD_WIDGET_RESET
} from '../AssetScreenDesign/constant';
import {
  GET_ALL_DASHBOARD,
  GET_ALL_WIDGET,
  EDIT_DASHBOARD,
  EDIT_DASHBOARD_RESET,
  DELETE_DASHBOARD,
  ADD_DASHBOARD,
  DELETE_DASHBOARD_RESET,
  GET_ALL_WIDGET_CONFIG,
  GET_ALL_WIDGET_CONFIG_EXTENDED,
  GET_WIDGET_CONFIG,
  CREATE_WIDGET,
  CREATE_WIDGET_RESET,
  EDIT_WIDGET,
  EDIT_WIDGET_RESET,
  DELETE_WIDGET,
  DELETE_WIDGET_RESET,
  CREATE_DASHBOARD_GLOBAL_ACTION,
  CREATE_DASHBOARD_GLOBAL_ACTION_RESET,
  GET_WIDGET_HISTORY,
  GET_DASHBOARD_CONFIG_HISTORY,
  GET_WIDGET_CONFIG_BY_ID,
  ADD_DASHBOARD_RESET,
  SET_POPOUT_WIDGET,
  OPEN_POPOUT_WIDGET,
  CLOSE_POPOUT_WIDGET,
  SORT_DASHBOARD,
  SORT_DASHBOARD_RESET,
  SORT_ALL_DASHBOARD,
  SET_FACTORY_BACKDROP,
  CREATE_CUSTOM_DASHBOARD,
  GET_CUSTOM_DASHBOARD,
	GET_CUSTOM_DASHBOARD_EXTENDED,
  CREATE_DASHBOARD_SEQUENCE,
	CREATE_DASHBOARD_SEQUENCE_RESET,
  GET_DASHBOARD_SEQUENCE,
	GET_DASHBOARD_SEQUENCE_EXTENDED,
  GET_SINGLE_DASHBOARD,
  CREATE_HEALTH_DATA,
  UPDATE_HEALTH_DATA,
	CREATE_HEALTH_DATA_RESET,
  DISCOVERY_UPGRADE_EMAIL,
  GET_ASSET_DYNAMIC_VARIABLES
} from './constant';

export function getAllDashboard(value) {
  return {
    type: GET_ALL_DASHBOARD,
    payload: value
  }
}

export function getSingleDashboard(value) {
  return {
    type: GET_SINGLE_DASHBOARD,
    payload: value
  }
}

export function sortAllDashboardState(value) {
  return {
    type: SORT_ALL_DASHBOARD,
    payload: value
  }
}

export function addDashboard(value) {
  return {
    type: ADD_DASHBOARD,
    payload: value
  }
}

export function resetAddDashboard(value) {
  return {
    type: ADD_DASHBOARD_RESET,
    payload: value
  }
}

export function editDashboard(value) {
  return {
    type: EDIT_DASHBOARD,
    payload: value
  }
}

export function resetEditDashboard(value) {
  return {
    type: EDIT_DASHBOARD_RESET,
    payload: value
  }
}
export function resetUpdateDashboardWidget(value) {
  return {
    type: EDIT_DASHBOARD_WIDGET_RESET,
    payload: value
  }
}
export function deleteDashboard(value) {
  return {
    type: DELETE_DASHBOARD,
    payload: value
  }
}

export function resetDeleteDashboard(value) {
  return {
    type: DELETE_DASHBOARD_RESET,
    payload: value
  }
}

export function getAllWidget(value) {
  return {
    type: GET_ALL_WIDGET,
    payload: value
  }
}

export function getAllWidgetConfig(value) {
  return {
    type: GET_ALL_WIDGET_CONFIG,
    payload: value
  }
}

export function getAllWidgetConfigExtended(value) {
  return {
    type: GET_ALL_WIDGET_CONFIG_EXTENDED,
    payload: value
  }
}

export function getWidgetConfigByID(value) {
  return {
    type: GET_WIDGET_CONFIG_BY_ID,
    payload: value
  }
}


export function getWidgetConfig(value) {
  return {
    type: GET_WIDGET_CONFIG,
    payload: value
  }
}

export function createWidget(value) {
  return {
    type: CREATE_WIDGET,
    payload: value
  }
}


export function resetCreateWidget(value) {
  return {
    type: CREATE_WIDGET_RESET,
    payload: value
  }
}

export function editWidget(value) {
  return {
    type: EDIT_WIDGET,
    payload: value
  }
}

export function resetEditWidget(value) {
  return {
    type: EDIT_WIDGET_RESET,
    payload: value
  }
}


export function deleteWidget(value) {
  return {
    type: DELETE_WIDGET,
    payload: value
  }
}


export function resetDeleteWidget(value) {
  return {
    type: DELETE_WIDGET_RESET,
    payload: value
  }
}

export function createDashboardGlobalAction(value) {
  return {
    type: CREATE_DASHBOARD_GLOBAL_ACTION,
    payload: value
  }
}

export function createDashboardGlobalResetAction(value) {
  return {
    type: CREATE_DASHBOARD_GLOBAL_ACTION_RESET,
    payload: value
  }
}


export function getWidgetHistory(value) {
  return {
    type: GET_WIDGET_HISTORY,
    payload: value
  }
}

export function getDashboardConfigHistory(value) {
  return {
    type: GET_DASHBOARD_CONFIG_HISTORY,
    payload: value
  }
}

export function getAllAssetList(value) {
  return {
    type: GET_ALL_ASSET_LIST,
    payload: value
  }
}

export function getWidgetDetailsOfTemplate(value) {
  return {
    type: GET_WIDGET_DETAILS_TEMPLATE,
    payload: value
  }
}

export function getGroupWidgetDetailsOfTemplate(value) {
  return {
      type: GET_GROUP_WIDGET_DETAILS_TEMPLATE,
      payload: value
  }
}

export function getTemplateWidgets(value) {
  return {
    type: GET_TEMPLATE_WIDGETS,
    payload: value
  }
}

export function deleteWidgetOfTemplate(value) {
  return {
    type: DELETE_WIDGET_OF_TEMPLATE,
    payload: value
  }
}

export function setPoputWidget(value) {
  return {
    type: SET_POPOUT_WIDGET,
    payload: value
  }
}

export function openPoputWidget(value) {
  return {
    type: OPEN_POPOUT_WIDGET,
    payload: value
  }
}

export function closePoputWidget(value) {
  return {
    type: CLOSE_POPOUT_WIDGET,
    payload: value
  }
}

export function sortDashboard(value) {
  return {
    type: SORT_DASHBOARD,
    payload: value
  }
}

export function createDashboardSequence(value) {
  return {
    type: CREATE_DASHBOARD_SEQUENCE,
    payload: value
  }
}

export const getDashboardSequenceExtended = (value) => {
  return {
    type: GET_DASHBOARD_SEQUENCE_EXTENDED,
    payload: value
  }
};

export function createDashboardSequenceReset(value) {
	return {
		type: CREATE_DASHBOARD_SEQUENCE_RESET,
		payload: value
	}
}

export function getDashboardSequence(value) {
  return {
    type: GET_DASHBOARD_SEQUENCE,
    payload: value
  }
}

export function resetSortDashboard(value) {
  return {
    type: SORT_DASHBOARD_RESET,
    payload: value
  }
}

export function setFactoryBackdrop(value) {
  return {
    type: SET_FACTORY_BACKDROP,
    payload: value
  }
}

export function getCustomDashboard(value) {
  return {
    type: GET_CUSTOM_DASHBOARD,
    payload: value
  }
}

export const getCustomDashboardExtended = (value) => {
  return {
    type: GET_CUSTOM_DASHBOARD_EXTENDED,
    payload: value
  };
};

export function createCustomDashboard(value) {
  return {
    type: CREATE_CUSTOM_DASHBOARD,
    payload: value
  }
}

export const createHealthData = (value)=>{
  return{
    type: CREATE_HEALTH_DATA,
    payload: value
  }
}

export const updateHealthData = (value)=>{
  return{
    type: UPDATE_HEALTH_DATA,
    payload: value
  }
}

export const createHealthDataReset = (value) => {
  return {
    type: CREATE_HEALTH_DATA_RESET
  };
};
export const discoveryUpgradeEmail = (value) => {
  return {
    type: DISCOVERY_UPGRADE_EMAIL,
    payload: value
  }
}

export const getAssetDynamicVariables = (value) => {
  return {
    type: GET_ASSET_DYNAMIC_VARIABLES,
    payload: value
  };
};